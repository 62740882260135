import "../styles/globals.css";
import type { AppProps } from "next/app";
import { IntercomProvider } from "react-use-intercom";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";
import * as Fathom from "fathom-client";
import Head from "next/head";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  createBrowserSupabaseClient,
  Session,
} from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { Database } from "../types/supabase";

const INTERCOM_APP_ID = "xecsxkrb";

function MyApp({
  Component,
  pageProps,
}: AppProps<{ initialSession: Session }>) {
  const [supabaseClient] = useState(() =>
    createBrowserSupabaseClient<Database>()
  );
  const router = useRouter();

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    Fathom.load("WOVVFPFX", {
      includedDomains: ["withcauseway.com", "dev.withcauseway.com"],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <SWRConfig
          value={{
            onError: (error, key) => {
              if (error.status !== 403 && error.status !== 404) {
                Sentry.captureException(new Error(error));
              }
            },
          }}
        >
          <Sentry.ErrorBoundary
            showDialog
            fallback={<p>An error has occurred</p>}
          >
            <Head>
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />

              <title>Donations that make a real difference | Causeway</title>
              <meta
                name="description"
                content="Build a giving portfolio that includes all of the causes you care about. We'll automate it to help maximize your impact."
              />

              {/* Twitter */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta property="twitter:domain" content="withcauseway.com" />
              <meta property="twitter:url" content="https://withcauseway.com" />
              <meta
                name="twitter:title"
                content="Donations that actually make a difference | Causeway"
              />
              <meta
                name="twitter:description"
                content="Build a giving portfolio that includes all of the causes you care about. We'll automate it to help maximize your impact."
              />
              <meta
                name="twitter:image"
                content="https://withcauseway.com/social-share.jpg"
              />

              {/* Facebook */}
              <meta property="og:url" content="https://withcauseway.com" />
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content="Donations that actually make a difference | Causeway"
              />
              <meta
                property="og:description"
                content="Build a giving portfolio that includes all of the causes you care about. We'll automate it to help maximize your impact."
              />
              <meta
                property="og:image"
                content="https://withcauseway.com/social-share.jpg"
              />
            </Head>
            <Component {...pageProps} />
          </Sentry.ErrorBoundary>
        </SWRConfig>
      </SessionContextProvider>
    </IntercomProvider>
  );
}

export default MyApp;
